import React, { useState, useEffect } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import PrivateRoute from "./utils/PrivateRoute";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));

const App = () => {
  const [auth, setAuth] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) setAuth(false);
  }, []);

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login setAuth={setAuth} {...props} />}
          />
          <PrivateRoute
            path="/"
            name="Home"
            authed={auth}
            setAuth={setAuth}
            component={TheLayout}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
